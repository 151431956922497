import React from 'react';
import { TextField } from 'mui-rff';
import { SENDING_FIRM_CASE_NUMBER } from '../Schema';
import { successFieldInputProps, successFieldStyle } from '../../Styles';

// 'sendingFirm.firmCaseNumber'
export const SendingFirmCaseNumberField = ({ sendingFirmCaseNumberMatchesIntegration}) => {

    return (
        <TextField
            label={SENDING_FIRM_CASE_NUMBER.label}
            name={SENDING_FIRM_CASE_NUMBER.path}
            required={SENDING_FIRM_CASE_NUMBER.required}
            sx={sendingFirmCaseNumberMatchesIntegration ? successFieldStyle : {}}
            InputProps={sendingFirmCaseNumberMatchesIntegration && successFieldInputProps}
            fieldProps={{ validate: SENDING_FIRM_CASE_NUMBER.validation }}
            fullWidth
        />
    )
}