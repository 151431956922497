import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Stack, Button, InputAdornment, DialogContent, DialogTitle, Typography, MenuItem, TextField as MuiTextField, Box, Tooltip, Divider, Snackbar, Collapse, Alert, AlertTitle, Paper, FormHelperText, IconButton, ListItemIcon, ListItemText, Menu, Chip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { LoadingButton } from "@mui/lab";
import { AutoAwesome, Close, CloudUploadOutlined, EditOutlined, InfoOutlined, MoreHorizOutlined } from '@mui/icons-material';
import { Form } from 'react-final-form';
import { TextField, Autocomplete, Switches } from 'mui-rff';
import DocumentUploader from '../DocumentUploader';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import Title from '../../CommonComponents/Title';
import { DiscardWork } from '../../dialogs/DialogText';
import { setCreateNewDemandOpen, setDocumentUploaderList } from '../../redux/slices/demandDomSlice';
import { uploadFiles } from '../../redux/thunks/demandData';
import { fetchCustomerData } from '../../redux/thunks/customerData';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableLiveTemplatesForCustomer, getConfigValue } from '../../api';
import { isLawFirmUser, isPrecedentUser, isLawFirmAdmin, isLawFirmApprover, isPrecedentAdmin } from '../../common-roles';
import { formatDecimal, getCurrentDate, cleanNumber, normalizeEmailAddress, formatPhoneNumber, MAX_DAYS_RELATIVE_DUE_DATE } from '../../common';
import { coverageList, stateLabelValues } from '../../common-data';
import { DOCUMENT_UPLOAD_FILE_TYPES, MAX_FILE_SIZE } from '../../common-document';
import { parseNestedObject } from '../../helpers/formHelpers';
import { buildCarrierAutoCompleteData, loadCarriers, validate, NO_TEMPLATE, buildUserAutoCompleteData, getSingleSelectAutoCompleteFilteredOptions, getMultiSelectAutoCompleteFilteredOptions, joinNames, getUserValues, successFieldStyle, successFieldInputProps } from './FormHelpers';
import { handleDemandCreationSave } from './Utilities';
import { theme } from '../../Theme';
import { useSaveDocumentDataMutation } from '../../services/documentData/documentDataApi';
import { useGetIntegrationMetadataQuery } from '../../services/integrations/integrationsApi';
import SaveAnimationDialog from '../../dialogs/SaveAnimationDialog';
import { setToast } from '../../redux/slices/globalToastSlice';
import { setDocumentStatus, setIsAiProcessingForCreateDocument } from '../../redux/slices/documentSlice';
import CreateNewDemandSkeleton from './CreateNewDemandSkeleton';
import { merge, get } from 'lodash';
import RequiredFieldsTooltip from './RequiredFieldsTooltip';
import { AUTOCOMPLETES_APPROVER, AUTOCOMPLETES_CARRIER, AUTOCOMPLETES_COLLABORATORS, AUTOCOMPLETES_PRIMARY_CONTACT, CLAIM_INFO_CLAIM_COVERAGE, CLAIM_INFO_CLAIMANT_FIRSTNAME, CLAIM_INFO_LOSS_COUNTY, CLAIM_INFO_LOSS_STATE, CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM, CUSTOMER_SPECIAL_NOTES, DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT, DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE, DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1, DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2, DEMAND_DETAILS_DEMAND_RESPONSE_CITY, DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE, DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE, DEMAND_DETAILS_DEMAND_RESPONSE_STATE, DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE, DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT, DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR, DEMAND_DETAILS_USING_RELATIVE_DUE_DATE, MUTATORS_APPROVER_IS_PRIMARY_CONTACT, MUTATORS_SHOW_ADJUSTER_CONTACT_INFO, MUTATORS_SHOW_CARRIER_CONTACT_INFO, RECIPIENT_ADJUSTER_EMAIL_ADDRESS, RECIPIENT_ADJUSTER_FIRST_NAME, RECIPIENT_ADJUSTER_LAST_NAME, RECIPIENT_ADJUSTER_PHONE_NUMBER, RECIPIENT_CARRIER_ADDRESS_1, RECIPIENT_CARRIER_ADDRESS_2, RECIPIENT_CARRIER_CITY, RECIPIENT_CARRIER_EMAIL_ADDRESS, RECIPIENT_CARRIER_FAX_NUMBER, RECIPIENT_CARRIER_PHONE_NUMBER, RECIPIENT_CARRIER_STATE, RECIPIENT_CARRIER_ZIP_CODE, SENDING_FIRM_CASE_NUMBER, SENDING_FIRM_TIN } from '../../document-fields';
import { useFetchAuthorFormDataQuery } from '../../services/authFormData/authFormDataApi';
import SearchIntegrationButton from './Integrations/SearchIntegrationButton';
import { resetIntegrationState, setIsNewCaseSelected } from '../../redux/slices/integrationsSlice';
import UploadAdditionalDocumentsDropdown from './UploadAdditionalDocumentsDropdown';
//
import { SendingFirmCaseNumberField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/SendingFirmFields';
import { DemandTemplateField } from '../../FormFieldsAndSchemas/Demand/FieldComponents/MetadataFields';
import {
    ClaimInfoClaimNumberField,
    ClaimInfoClaimantFirstNameField,
    ClaimInfoClientIsInjuredPartyField,
    ClaimInfoInjuredPartyFullNameField,
    ClaimInfoInsuredFirstNameField,
    ClaimInfoClientIsTortfeasorField,
    ClaimInfoTortfeasorField,
    ClaimInfoDateOfLossField
} from '../../FormFieldsAndSchemas/Demand/FieldComponents/ClaimInfoFields';


const CreateNewDemand = ({ useDemandNotesEnabled }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //redux state
    const { userData, user } = useSelector((state) => state.User);
    const { customerId } = userData;
    const { customerData } = useSelector((state) => state.Customer);

    const { documentUploaderList } = useSelector(state => state.DemandDom); //todo: create a separate slice for document uploads
    const hasUploadErrors = documentUploaderList.some((file) => file.error);

    const { integratedCase: { id: matterId, claimNumber, clientName }, isNewCaseSelected } = useSelector((state) => state.Integrations);
    const isIntegrationCaseLinked = matterId && claimNumber;

    //rtk query
    const [saveDocumentData] = useSaveDocumentDataMutation();

    const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
    const { displayName: integrationName, integrationId } = integrationMetadata;

    const { data: authorFormData = {} } = useFetchAuthorFormDataQuery({ ...isPrecedentUser(userData) && { customerId } });
    //local state
    const [carriersData, setCarriersData] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [demandErrorState, setDemandErrorState] = useState({ show: false, severity: 'error', title: '', message: '' });
    const [dialogState, setDialogState] = useState({ discardChanges: false });
    const [toastState, setToastState] = useState({ show: false, severity: 'info', message: '' })
    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [defaultCustomerTemplate, setDefaultCustomerTemplate] = useState(NO_TEMPLATE);
    const [defaultRelativeDueDate, setDefaultRelativeDueDate] = useState(0);
    const [formDataLoaded, setFormDataLoaded] = useState(false);
    const [anchorEl, setAnchorEl] = useState();
    //configs
    const isEditBeforeAiProcessingEnabled = customerData?.customerEntityData?.canEditBeforeAiProcessing ?? false;
    const showIntegration = Boolean(integrationId);

    //data mapping
    const { users = [], approvers = [] } = authorFormData;

    const approverIds = approvers?.map(approver => approver.userId) || [];
    const attorneys = users ? users.filter(user => approverIds.includes(user.userId)) : [];
  
    const attorney_auto_complete_data = buildUserAutoCompleteData(attorneys);
    const user_data_for_auto_completes = buildUserAutoCompleteData(users);
    const primaryContact_auto_complete_data = user_data_for_auto_completes;
    const carrier_auto_complete_data = buildCarrierAutoCompleteData(carriersData);
    const collaborators_auto_complete_data = user_data_for_auto_completes;
    const defaultCollaboratorsUserIds = customerData?.defaultCollaboratorsUserIds || [];

    //useEffects
    useEffect(() => {
        dispatch(resetIntegrationState());
        return () => {
            dispatch(setCreateNewDemandOpen(false));
            dispatch(resetIntegrationState());
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    }, []);

    useEffect(() => { dispatch(fetchCustomerData({ customerId, user })); }, [customerId]);

    useEffect(() => {
        if (!user) return;

        const fetchData = async () => {
            try {
                loadCarriers(user, setCarriersData);

                // Await all data fetching promises
                const [templatesResponse, defaultTemplateResponse, relativeDueDateResponse] = await Promise.all([
                    getAvailableLiveTemplatesForCustomer(user),
                    getConfigValue('defaultTemplateId', null, user),
                    getConfigValue('defaultRelativeDueDate', null, user),
                ]);

                if (templatesResponse?.templates) {
                    setAvailableTemplates([{ templateId: NO_TEMPLATE, templateName: NO_TEMPLATE }, ...templatesResponse.templates]);
                }
                if (defaultTemplateResponse) setDefaultCustomerTemplate(defaultTemplateResponse);
                if (relativeDueDateResponse) setDefaultRelativeDueDate(relativeDueDateResponse);

                setFormDataLoaded(true);

            } catch (error) {
                dispatch(setToast({ isOpen: true, message: 'Failed to load data', severity: 'error' }));
            }
        };

        fetchData();
    }, [user]);

    const currentTemplate = availableTemplates.find(template => template.templateId === defaultCustomerTemplate);
    const currentTemplateDemandConfig = currentTemplate?.defaultDemandConfig || {};

    const handleSaveSuccessCallback = (documentId) => {
        if (!documentId) {
            dispatch(setToast({ isOpen: true, message: 'Failed to create demand', severity: 'error' }));
            dispatch(setCreateNewDemandOpen(false));
        }
        if (isLawFirmUser(userData)) {
            dispatch(setCreateNewDemandOpen(false));
            navigate('/l/' + documentId);
        } else if (isPrecedentUser(userData)) {
            dispatch(setCreateNewDemandOpen(false));
            navigate('/a/' + documentId);
        }
    }

    const handleClose = () => dispatch(setCreateNewDemandOpen(false))
    const closeMenu = () => setAnchorEl(null);

    const isPrimaryContactApprover = (userId) => {
        const primaryContact = users.find((user) => user.userId === userId);
        return isLawFirmAdmin(primaryContact) || isLawFirmApprover(primaryContact) || isPrecedentAdmin(primaryContact);
    };

    const initialValues = useMemo(() => {
        const currentDate = getCurrentDate();
        currentTemplateDemandConfig?.demandIsDeliverable && delete currentTemplateDemandConfig.demandIsDeliverable;

        const initialPrimaryContact = { label: joinNames(userData), value: getUserValues(userData), userId: userData.userId };

        const initialCollaborators = defaultCollaboratorsUserIds.length > 0 ?
            defaultCollaboratorsUserIds.map(userId => {
                const user = users.find(user => user.userId === userId);
                return { label: joinNames(user), value: getUserValues(user), userId }
            }) : [];
        initialCollaborators.push(initialPrimaryContact);

        return merge(
            {
                metadata: {
                    demandTemplateId: defaultCustomerTemplate,
                },
                sendingFirm: {
                    caseManagers: []
                },
                claimInfo: {
                    dateOfLoss: currentDate,
                    lossState: 'none',
                    clientIsInjuredParty: true,
                    insuredIsTortfeasor: true
                },
                demandDetails: {
                    displayDemandAmount: false,
                    policyLimitDemandIndicator: false,
                    demandResponseRelativeDueDate: defaultRelativeDueDate ?? '',
                    usingRelativeDueDate: defaultRelativeDueDate == 0 ? false : true
                },
                mutators: {
                    attorneyIsPrimaryContact: isPrimaryContactApprover(initialPrimaryContact.userId),
                },
                autoCompletes: {
                    primaryContact: initialPrimaryContact,
                    collaborators: initialCollaborators
                }
            },
            currentTemplateDemandConfig,
        );
    }, [users, userData, defaultCustomerTemplate, defaultCollaboratorsUserIds, isPrimaryContactApprover]);

    const handleSaveClick = async (values, sendForAiProcessing = true) => {
        const body = parseNestedObject({
            ...values,
            documentType: 'COMPOSITEDEMAND',
            damagesSummary: {
                ...values.damagesSummary,
                demandDamagesAmount: values?.damagesSummary?.demandDamagesAmount ? cleanNumber(values.damagesSummary.demandDamagesAmount) : ''
            },
            demandDetails: {
                ...values.demandDetails,
                demandOfferToSettleAmount: values?.demandDetails?.demandOfferToSettleAmount ? cleanNumber(values.demandDetails.demandOfferToSettleAmount) : ''
            },
            sendingFirm: {
                ...values.sendingFirm,
                //use autoComplete values to populate respective fields on sendingFirm object onSave
                attorney: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.primaryContact?.value : values?.autoCompletes?.attorney?.value,
                primaryContact: values?.autoCompletes?.primaryContact?.value,
                caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
            },
            claimInfo: {
                ...values.claimInfo,
                insuredPolicyLimitsAsUnderstoodByFirm: values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm ? cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm) : ''
            },
            defaultDeliveryInformationEnabled: values?.autoCompletes?.carrier?.value?.defaultDeliveryInformationEnabled,
            recipientCarrier: {
                //use autoComplete values to populate respective fields on recipientCarrier object onSave
                ...values?.recipientCarrier,
                carrierCustomerAliasId: values?.autoCompletes?.carrier?.value?.carrierCustomerAliasId,
                carrierCommonName: values?.autoCompletes?.carrier?.value?.carrierCommonName || values?.recipientCarrier?.carrierCommonName,
                //use recipientCarrier.contactInfo values to populate respective fields b/c these fields are not fully controlled by autoComplete selection
                contactInfo: { ...values?.recipientCarrier?.contactInfo }
            },
            recipientAdjuster: {
                ...values.recipientAdjuster,
                contactInfo: {
                    ...values?.recipientAdjuster?.contactInfo,
                    emailAddress: values?.recipientAdjuster?.contactInfo?.emailAddress ? normalizeEmailAddress(values.recipientAdjuster.contactInfo.emailAddress) : '',
                    phoneNumber: values?.recipientAdjuster?.contactInfo?.phoneNumber ? formatPhoneNumber(values.recipientAdjuster.contactInfo.phoneNumber) : ''
                }
            },
            customerSpecialNotes: values?.customerSpecialNotes ? values.customerSpecialNotes : '',
            ...(isIntegrationCaseLinked && {
                caseManagementMetadata: {
                    sourceSystemId: integrationId,
                    matterId: claimNumber,
                    matterTechId: matterId,
                },
            }
            ),
        });
        delete body.autoCompletes;  //remove autoCompletes from document body before submitting
        delete body.mutators;  //remove mutators from document body before submitting

        if (sendForAiProcessing) {
            dispatch(setDocumentStatus('AwaitingMedicalExtraction'));
            dispatch(setIsAiProcessingForCreateDocument(true));
        }

        handleDemandCreationSave({
            data: body,
            dispatch,
            saveDocumentData,
            setIsSaving: setFormSubmitting,
            callbacks: {
                handleSaveSuccessCallback,
                uploadFiles: (documentId) => dispatch(uploadFiles({ documentId, user, sendForAiProcessing, shouldFetchDocumentData: false })),
            }
        });
    }

    const haveFilesUploaded = documentUploaderList.length > 0;

    const handleCloseButtonClick = (dirty) => dirty ? setDialogState({ ...dialogState, discardChanges: true }) : handleClose();

    const confirmationDialogProps = {
        dialogOpen: dialogState.discardChanges,
        dialogText: DiscardWork.text,
        cancelButtonText: DiscardWork.cancelText,
        okButtonText: DiscardWork.confirmText,
        handleCancel: () => {
            setDialogState({ ...dialogState, discardChanges: false });
        }
    }

    const handleFileProcessing = (inputFiles) => {
        const files = [...inputFiles].map((file) => {
            // Spread operator does not work on file object.
            file.isExternalFile = false;
            if (file.size > MAX_FILE_SIZE) {
                file.error = 'File size cannot exceed 150MB';
            }
            return file;
        });

        const newFileList = [...documentUploaderList, ...files];
        //iterate through new file list and if files have same name- remove the duplicate
        const uniqueFiles = newFileList.filter((file, index, self) =>
            index === self.findIndex((t) => (
                t.name === file.name
            ))
        )

        return uniqueFiles;
    };


    return (
        <>
            <Form
                validate={(values) => validate({ values, userData })}
                onSubmit={() => null}
                initialValues={initialValues}
                render={({ handleSubmit, form, values, valid, errors, dirty, touched }) => {
                    const carrierId = values?.autoCompletes?.carrier?.value?.carrierId;

                    if (isNewCaseSelected) {
                        form.change(CLAIM_INFO_CLAIMANT_FIRSTNAME.path, clientName);
                        form.change(SENDING_FIRM_CASE_NUMBER.path, claimNumber);
                        dispatch(setIsNewCaseSelected(false));
                        dispatch(setToast({ isOpen: true, message: `Successfully imported details from ${integrationName}`, severity: 'success' }));
                    }

                    const sendingFirmCaseNumberMatchesIntegration = isIntegrationCaseLinked && get(values, SENDING_FIRM_CASE_NUMBER.path) === claimNumber;
                    const clientNameMatchesIntegration = isIntegrationCaseLinked && get(values, CLAIM_INFO_CLAIMANT_FIRSTNAME.path) === clientName;

                    const primaryContactApproverHelperText = isPrimaryContactApprover(values?.autoCompletes?.primaryContact?.userId) ? '' : `You must select an approver because ${values?.autoCompletes?.primaryContact?.label} does not have permission to approve demands`;

                    return (
                        <form id="createDemand" onSubmit={handleSubmit}>
                            <SaveAnimationDialog
                                isSaving={formSubmitting}
                                message={"Submitting demand..."}
                            />

                            <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                                <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>

                                        <IconButton onClick={e => handleCloseButtonClick(dirty || haveFilesUploaded)} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                            <Close color={'white'} />
                                        </IconButton>

                                        <Title color={theme.palette.white.main}>Compose demand</Title>

                                    </Stack>

                                    {/* large viewport primary buttons */}
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        alignItems={"center"}
                                        justifyContent={"flex-end"}
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'flex'
                                            }
                                        }}
                                    >
                                        <UploadAdditionalDocumentsDropdown
                                            handleFileProcessing={handleFileProcessing}
                                            DOCUMENT_UPLOAD_FILE_TYPES={DOCUMENT_UPLOAD_FILE_TYPES}
                                        />

                                        <RequiredFieldsTooltip values={values} touched={touched} errors={errors} valid={valid} hasUploadErrors={hasUploadErrors}>

                                            {isEditBeforeAiProcessingEnabled && (
                                                <LoadingButton
                                                    disabled={!valid || formSubmitting || !dirty || !haveFilesUploaded || hasUploadErrors}
                                                    sx={{ '&:disabled': { color: ("rgba(255,255,255,0.2)"), borderColor: ("rgba(255,255,255,0.2)") } }}
                                                    onClick={() => {
                                                        setDemandErrorState({ ...demandErrorState, show: false });
                                                        handleSaveClick(values, false)
                                                    }}
                                                    variant="outlined"
                                                    color="white"
                                                >
                                                    <span>Save and edit before AI processing</span>
                                                </LoadingButton>
                                            )}
                                        </RequiredFieldsTooltip>
                                        <RequiredFieldsTooltip values={values} touched={touched} errors={errors} valid={valid} hasUploadErrors={hasUploadErrors}>

                                            <Box sx={{ '& > button': { m: 1 } }}>
                                                <LoadingButton
                                                    disabled={!valid || formSubmitting || !dirty || !haveFilesUploaded || hasUploadErrors}
                                                    sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                                                    onClick={() => {
                                                        setDemandErrorState({ ...demandErrorState, show: false });
                                                        handleSaveClick(values, true)
                                                    }}
                                                    variant="contained"
                                                    color="white"
                                                >
                                                    Submit for AI processing
                                                </LoadingButton>
                                            </Box>
                                        </RequiredFieldsTooltip>

                                    </Stack>

                                    {/* small viewport primary buttons (in a menu) */}
                                    <Button
                                        variant="contained"
                                        color="white"
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget);
                                        }}
                                        endIcon={<MoreHorizOutlined />}
                                        sx={{
                                            display: {
                                                xs: 'flex',
                                                md: 'none'
                                            }
                                        }}
                                    >
                                        Actions
                                    </Button>
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={closeMenu}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >

                                        {haveFilesUploaded &&
                                            <Box>
                                                <input
                                                    accept={DOCUMENT_UPLOAD_FILE_TYPES}
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    multiple
                                                    type="file"

                                                    onChange={(e) => {
                                                        const uniqueFiles = handleFileProcessing(e.target.files);
                                                        dispatch(setDocumentUploaderList(uniqueFiles));
                                                    }}

                                                />
                                                <label htmlFor="raised-button-file">
                                                    <MenuItem key="upload-docs"
                                                        onClick={closeMenu}
                                                    >
                                                        <ListItemIcon>
                                                            <CloudUploadOutlined />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Upload documents" />
                                                    </MenuItem>
                                                </label>
                                            </Box>
                                        }

                                        <RequiredFieldsTooltip errors={errors} values={values} touched={touched} valid={valid} hasUploadErrors={hasUploadErrors} >
                                            {Boolean(isEditBeforeAiProcessingEnabled) &&
                                                <MenuItem
                                                    key="edit-before-ai-processing"
                                                    disabled={!valid || formSubmitting || !dirty || !haveFilesUploaded || hasUploadErrors}
                                                    onClick={() => {
                                                        setDemandErrorState({ ...demandErrorState, show: false });
                                                        handleSaveClick(values, false)
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <EditOutlined />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Save and edit before AI processing" />
                                                </MenuItem>
                                            }

                                            <MenuItem
                                                key="submit for AI processing"
                                                disabled={!valid || formSubmitting || !dirty || !haveFilesUploaded || hasUploadErrors}
                                                onClick={() => {
                                                    setDemandErrorState({ ...demandErrorState, show: false });
                                                    handleSaveClick(values, true)
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <AutoAwesome />
                                                </ListItemIcon>
                                                <ListItemText primary="Submit for AI processing" />
                                            </MenuItem>
                                        </RequiredFieldsTooltip>
                                    </Menu>

                                </Stack>
                            </DialogTitle>

                            <DialogContent sx={{ my: '0px', py: '0px', overflow: 'visible' }}>
                                <Grid container spacing={2} sx={{ mt: 8 }}>
                                    {demandErrorState.show &&
                                        <Grid xs={12}>
                                            <Collapse in={demandErrorState.show}>
                                                <Alert severity={demandErrorState.severity}>
                                                    <AlertTitle>{demandErrorState.title}</AlertTitle>
                                                    {demandErrorState.message}
                                                </Alert>
                                            </Collapse>
                                        </Grid>
                                    }
                                    {/* Left Column */}
                                    <Grid xs={12} sm={12} md={12} lg={6} xl={6} sx={{ paddingX: 0, pb: 5, marginX: 0, }} >
                                        <fieldset style={{ paddingLeft: 0, paddingRight: 0, marginLeft: 0, marginRight: 0 }} disabled={formSubmitting}>
                                           
                                            <>
                                                {!formDataLoaded ?

                                                    <CreateNewDemandSkeleton />
                                                    :
                                                    <Stack spacing={2} divider={<Divider flexItem />} >

                                                        {showIntegration && <SearchIntegrationButton source="compose" />}

                                                        <DemandTemplateField form={form} values={values} availableTemplates={availableTemplates} />

                                                        <Stack>

                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }} >
                                                                Case details
                                                            </Typography>

                                                            <Grid container spacing={2}>

                                                                <Grid xs={12} md={6}>
                                                                    <SendingFirmCaseNumberField sendingFirmCaseNumberMatchesIntegration={sendingFirmCaseNumberMatchesIntegration} />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <ClaimInfoClaimNumberField />
                                                                </Grid>

                                                                <Grid xs={12} md={12}>
                                                                    <Stack spacing={1} >

                                                                        <ClaimInfoClaimantFirstNameField
                                                                            form={form}
                                                                            values={values}
                                                                            attorney_auto_complete_data={attorney_auto_complete_data}
                                                                            carrier_auto_complete_data={carrier_auto_complete_data}
                                                                            user_data_for_auto_completes={user_data_for_auto_completes}
                                                                            clientNameMatchesIntegration={clientNameMatchesIntegration}
                                                                        />

                                                                        <ClaimInfoClientIsInjuredPartyField form={form} values={values} />

                                                                        {!values.claimInfo?.clientIsInjuredParty &&
                                                                            <ClaimInfoInjuredPartyFullNameField />
                                                                        }

                                                                    </Stack>
                                                                </Grid>

                                                                <Grid xs={12} md={12}>
                                                                    <Stack spacing={1}>

                                                                        <ClaimInfoInsuredFirstNameField form={form} values={values} />

                                                                        <ClaimInfoClientIsTortfeasorField form={form} values={values} />

                                                                        {!values.claimInfo?.insuredIsTortfeasor &&
                                                                            <ClaimInfoTortfeasorField form={form} values={values} />
                                                                        }

                                                                    </Stack>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>

                                                        {/* loss details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>

                                                                    <ClaimInfoDateOfLossField />

                                                                </Grid>
                                                                <Grid xs={12} md={6}>

                                                                    <TextField
                                                                        fullWidth
                                                                        required={CLAIM_INFO_CLAIM_COVERAGE.required}
                                                                        label={CLAIM_INFO_CLAIM_COVERAGE.label}
                                                                        name={CLAIM_INFO_CLAIM_COVERAGE.path}
                                                                        select
                                                                    >
                                                                        {coverageList.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        label={CLAIM_INFO_LOSS_STATE.label}
                                                                        name={CLAIM_INFO_LOSS_STATE.path}
                                                                        select
                                                                        required={CLAIM_INFO_LOSS_STATE.required}
                                                                    >
                                                                        {[{ label: 'Select a state', value: 'none' }, ...stateLabelValues].map((option, idx) => (
                                                                            <MenuItem key={idx} value={option.value} disabled={idx === 0} >
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={CLAIM_INFO_LOSS_COUNTY.required}
                                                                        label={CLAIM_INFO_LOSS_COUNTY.label}
                                                                        name={CLAIM_INFO_LOSS_COUNTY.path}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        </Stack>

                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                                            <Grid container spacing={2}>

                                                                <Grid xs={12}>
                                                                    <Autocomplete
                                                                        name={AUTOCOMPLETES_CARRIER.path}
                                                                        required={AUTOCOMPLETES_CARRIER.required}
                                                                        label={AUTOCOMPLETES_CARRIER.label}
                                                                        freeSolo
                                                                        fullWidth
                                                                        selectOnFocus
                                                                        clearOnBlur
                                                                        handleHomeEndKeys
                                                                        blurOnSelect
                                                                        options={carrier_auto_complete_data}
                                                                        filterOptions={(options, params) => {
                                                                            const filtered = getSingleSelectAutoCompleteFilteredOptions(options, params);
                                                                            const { inputValue } = params;
                                                                            // Suggest the creation of a new value
                                                                            const isExisting = options.some((option) => inputValue === option.label);
                                                                            if (inputValue !== '' && !isExisting) {
                                                                                filtered.push({
                                                                                    value: { carrierCommonName: inputValue },
                                                                                    label: `Add "${inputValue}"`,
                                                                                });
                                                                            }

                                                                            return filtered;
                                                                        }}
                                                                        isOptionEqualToValue={(option, value) => option?.label === value}
                                                                        fieldProps={{ format: (value) => value?.value?.carrierCommonName }}
                                                                        onChange={(e, selection) => {
                                                                            form.change('recipientCarrier', selection?.value || {
                                                                                carrierCommonName: '',
                                                                                carrierCustomerAliasId: '',
                                                                                contactInfo: {
                                                                                    address: {
                                                                                        city: '',
                                                                                        state: '',
                                                                                        street1: '',
                                                                                        street2: '',
                                                                                        zipCode: ''
                                                                                    },
                                                                                    emailAddress: '',
                                                                                    faxNumber: '',
                                                                                    phoneNumber: ''
                                                                                }
                                                                            })
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                {!carrierId &&
                                                                    <>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_FAX_NUMBER.required}
                                                                                label={RECIPIENT_CARRIER_FAX_NUMBER.label}
                                                                                name={RECIPIENT_CARRIER_FAX_NUMBER.path}
                                                                                type='tel'
                                                                                autoComplete='off'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                            />
                                                                        </Grid>

                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_EMAIL_ADDRESS.required}
                                                                                label={RECIPIENT_CARRIER_EMAIL_ADDRESS.label}
                                                                                name={RECIPIENT_CARRIER_EMAIL_ADDRESS.path}
                                                                                type='email'
                                                                                autoComplete='off'
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                }
                                                                <Grid xs={12}>
                                                                    <Switches
                                                                        name={MUTATORS_SHOW_CARRIER_CONTACT_INFO.path}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change('mutators.showCarrierContactInfo', checked)
                                                                            if (!checked) {
                                                                                form.change('recipientCarrier.contactInfo.phoneNumber', '');
                                                                                form.change('recipientCarrier.contactInfo.address.street1', '');
                                                                                form.change('recipientCarrier.contactInfo.address.street2', '');
                                                                                form.change('recipientCarrier.contactInfo.address.city', '');
                                                                                form.change('recipientCarrier.contactInfo.address.state', '');
                                                                                form.change('recipientCarrier.contactInfo.address.zipCode', '');
                                                                            }
                                                                        }}
                                                                        data={{ label: MUTATORS_SHOW_CARRIER_CONTACT_INFO.label, value: true }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                {values?.mutators?.showCarrierContactInfo &&
                                                                    <>
                                                                        <Grid xs={12}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_PHONE_NUMBER.required}
                                                                                label={RECIPIENT_CARRIER_PHONE_NUMBER.label}
                                                                                name={RECIPIENT_CARRIER_PHONE_NUMBER.path}
                                                                                type='tel'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_ADDRESS_1.required}
                                                                                label={RECIPIENT_CARRIER_ADDRESS_1.label}
                                                                                name={RECIPIENT_CARRIER_ADDRESS_1.path}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_ADDRESS_2.required}
                                                                                label={RECIPIENT_CARRIER_ADDRESS_2.label}
                                                                                name={RECIPIENT_CARRIER_ADDRESS_2.path}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={4}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_CITY.required}
                                                                                label={RECIPIENT_CARRIER_CITY.label}
                                                                                name={RECIPIENT_CARRIER_CITY.path}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={6} md={4}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_STATE.required}
                                                                                label={RECIPIENT_CARRIER_STATE.label}
                                                                                name={RECIPIENT_CARRIER_STATE.path}
                                                                                select
                                                                            >
                                                                                {stateLabelValues.map((option) => (
                                                                                    <MenuItem key={option.value} value={option.value}>
                                                                                        {option.label}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                        </Grid>
                                                                        <Grid xs={6} md={4}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_CARRIER_ZIP_CODE.required}
                                                                                label={RECIPIENT_CARRIER_ZIP_CODE.label}
                                                                                name={RECIPIENT_CARRIER_ZIP_CODE.path}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                }

                                                                <Grid xs={12}>
                                                                    <Switches
                                                                        name={MUTATORS_SHOW_ADJUSTER_CONTACT_INFO.path}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change('mutators.showAdjusterContactInfo', checked)
                                                                            if (!checked) {
                                                                                form.change('recipientAdjuster.contactInfo.phoneNumber', '');
                                                                                form.change('recipientAdjuster.contactInfo.emailAddress', '');
                                                                                form.change('recipientAdjuster.firstName', '');
                                                                                form.change('recipientAdjuster.lastName', '');
                                                                            }
                                                                        }}
                                                                        data={{ label: MUTATORS_SHOW_ADJUSTER_CONTACT_INFO.label, value: true }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                {values?.mutators?.showAdjusterContactInfo &&
                                                                    <>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_ADJUSTER_FIRST_NAME.required}
                                                                                label={RECIPIENT_ADJUSTER_FIRST_NAME.label}
                                                                                name={RECIPIENT_ADJUSTER_FIRST_NAME.path}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_ADJUSTER_LAST_NAME.required}
                                                                                label={RECIPIENT_ADJUSTER_LAST_NAME.label}
                                                                                name={RECIPIENT_ADJUSTER_LAST_NAME.path}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_ADJUSTER_PHONE_NUMBER.required}
                                                                                label={RECIPIENT_ADJUSTER_PHONE_NUMBER.label}
                                                                                name={RECIPIENT_ADJUSTER_PHONE_NUMBER.path}
                                                                                type='tel'
                                                                                onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid xs={12} md={6}>
                                                                            <TextField
                                                                                fullWidth
                                                                                required={RECIPIENT_ADJUSTER_EMAIL_ADDRESS.required}
                                                                                label={RECIPIENT_ADJUSTER_EMAIL_ADDRESS.label}
                                                                                name={RECIPIENT_ADJUSTER_EMAIL_ADDRESS.path}
                                                                                type='email'
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                }


                                                            </Grid>
                                                        </Stack>

                                                        {/* demand details */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>
                                                                    <Switches
                                                                        name={DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.path}
                                                                        required={DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.required}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change(DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.path, checked)
                                                                            if (!checked) {
                                                                                form.change('claimInfo.insuredPolicyLimitsAsUnderstoodByFirm', '');
                                                                                form.change('demandDetails.demandOfferToSettleAmount', '');
                                                                                form.change('demandDetails.policyLimitDemandIndicator', false);
                                                                            }
                                                                            else {
                                                                                form.change('demandDetails.policyLimitDemandIndicator', true);
                                                                                form.change(
                                                                                    'demandDetails.demandOfferToSettleAmount',
                                                                                    values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm || ''
                                                                                );
                                                                            }
                                                                        }}
                                                                        data={{ label: DEMAND_DETAILS_DISPLAY_DEMAND_AMOUNT.label, value: false }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    {values?.demandDetails?.displayDemandAmount !== false && (
                                                                        <Switches
                                                                            name={DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path}
                                                                            required={DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.required}
                                                                            onChange={(e) => {
                                                                                const checked = e.target.checked;
                                                                                form.change(DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.path, checked)
                                                                                if (!checked) {
                                                                                    form.change('demandDetails.demandOfferToSettleAmount', '');
                                                                                }
                                                                                else {
                                                                                    form.change(
                                                                                        'demandDetails.demandOfferToSettleAmount',
                                                                                        values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
                                                                                    );
                                                                                }
                                                                            }}
                                                                            data={{ label: DEMAND_DETAILS_DISPLAY_POLICY_LIMIT_INDICATOR.label, value: false }}
                                                                            color="secondary"
                                                                        />
                                                                    )}
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        required={CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.required}
                                                                        label={CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.label}
                                                                        name={CLAIM_INFO_POLICY_LIMIT_UNDERSTOOD_BY_FIRM.path}
                                                                        onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                        onChange={(e) => {
                                                                            if (values?.demandDetails?.policyLimitDemandIndicator) {
                                                                                form.change("demandDetails.demandOfferToSettleAmount", e.target.value)
                                                                            }
                                                                            form.change("claimInfo.insuredPolicyLimitsAsUnderstoodByFirm", e.target.value)
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.required}
                                                                        label={DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.label}
                                                                        name={DEMAND_DETAILS_DEMAND_OFFER_TO_SETTLE.path}
                                                                        onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                                                        disabled={values?.demandDetails?.policyLimitDemandIndicator}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <TextField
                                                                            fullWidth
                                                                            required={DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.required}
                                                                            label={DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.label}
                                                                            name={DAMAGES_SUMMARY_DEMAND_DAMAGES_AMOUNT.path}
                                                                            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                                endAdornment: <Tooltip title="This optional field captures the expected amount of medical charges that have been uploaded for the demand." arrow placement="top">
                                                                                    <div style={{ marginTop: '8px' }}>
                                                                                        <InfoOutlined color="action" />
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }}
                                                                        />
                                                                    </Stack>
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={SENDING_FIRM_TIN.required}
                                                                        label={SENDING_FIRM_TIN.label}
                                                                        name={SENDING_FIRM_TIN.path}
                                                                    />
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    {values?.demandDetails?.usingRelativeDueDate === true ?
                                                                        <TextField
                                                                            required={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.required}
                                                                            name={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.path}
                                                                            label={DEMAND_DETAILS_DEMAND_RESPONSE_RELATIVE_DUE_DATE.label}
                                                                            type="number"
                                                                            fullWidth
                                                                            onChange={(e) => {
                                                                                //clip the input values to the range (1, MAX_DAYS_RELATIVE_DUE_DATE)
                                                                                e.target.value = Math.min(Math.max(parseInt(e.target.value), 1), MAX_DAYS_RELATIVE_DUE_DATE);
                                                                                form.change('demandDetails.demandResponseRelativeDueDate', e.target.value);
                                                                                form.change('demandDetails.demandResponseDueDate', '');
                                                                            }}
                                                                            onInput={(e) => {
                                                                                e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Filter out non-integer values
                                                                            }}
                                                                            value={values?.demandDetails?.demandResponseRelativeDueDate || ''}
                                                                            InputProps={{
                                                                                endAdornment:
                                                                                    <Tooltip title="Using a relative due date will automatically calculate and adjust the due date until the demand is sent." arrow placement="top">
                                                                                        <div style={{ marginTop: '8px' }}>
                                                                                            <InfoOutlined color="action" />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                            }}
                                                                        />
                                                                        :
                                                                        <TextField
                                                                            name={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.path}
                                                                            fullWidth
                                                                            required={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.required}
                                                                            inputProps={{ min: getCurrentDate() }}
                                                                            label={DEMAND_DETAILS_DEMAND_RESPONSE_DUE_DATE.label}
                                                                            type="date"
                                                                            InputLabelProps={{ shrink: true }}
                                                                            onChange={(e) => {
                                                                                form.change('demandDetails.demandResponseDueDate', e.target.value)
                                                                                form.change('demandDetails.demandResponseRelativeDueDate', '')
                                                                            }}
                                                                            value={values?.demandDetails?.demandResponseDueDate || ''}
                                                                            error={Boolean(errors.demandDetails?.demandResponseDueDate)}
                                                                            helperText={
                                                                                !errors.demandDetails?.demandResponseDueDate &&
                                                                                    values?.demandDetails?.demandResponseDueDate &&
                                                                                    moment(values.demandDetails.demandResponseDueDate).isAfter(moment().add(1, 'years'))
                                                                                    ? 'Date is more than 1 year from today'
                                                                                    : errors.demandDetails?.demandResponseDueDate
                                                                            }
                                                                        />
                                                                    }
                                                                </Grid>

                                                                <Grid xs={12} md={6}>
                                                                    <Stack direction={"row"} spacing={2}>
                                                                        <Switches
                                                                            name={DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.path}
                                                                            onChange={(e) => {
                                                                                const checked = e.target.checked;
                                                                                form.change('demandDetails.usingRelativeDueDate', checked)
                                                                                if (!checked) {
                                                                                    form.change('demandDetails.demandResponseRelativeDueDate', '');
                                                                                    form.change('demandDetails.usingRelativeDueDate', false);
                                                                                }
                                                                                else {
                                                                                    form.change('demandDetails.demandResponseDueDate', '');
                                                                                    form.change('demandDetails.usingRelativeDueDate', true);
                                                                                }
                                                                            }}
                                                                            data={{ label: DEMAND_DETAILS_USING_RELATIVE_DUE_DATE.label, value: true }}
                                                                            color="secondary"
                                                                        />
                                                                    </Stack>
                                                                </Grid>

                                                            </Grid>
                                                        </Stack>

                                                        {/* case team */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                                            <Grid container spacing={2}>      
                                                                <Grid xs={12}>
                                                                    <Autocomplete
                                                                        name={AUTOCOMPLETES_PRIMARY_CONTACT.path}
                                                                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                        fullWidth
                                                                        blurOnSelect
                                                                        filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                        options={primaryContact_auto_complete_data}
                                                                        onChange={(e, value) => {
                                                                            if (!isPrimaryContactApprover(value?.userId)) {
                                                                                form.change(MUTATORS_APPROVER_IS_PRIMARY_CONTACT.path, false)
                                                                            } 
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <>
                                                                                <MuiTextField
                                                                                    {...params}
                                                                                    label={AUTOCOMPLETES_PRIMARY_CONTACT.label}
                                                                                    required={AUTOCOMPLETES_PRIMARY_CONTACT.required}
                                                                                    onClick={(e) => form.blur(AUTOCOMPLETES_PRIMARY_CONTACT.path)}
                                                                                    error={Boolean(touched[AUTOCOMPLETES_PRIMARY_CONTACT.path] && errors.autoCompletes?.primaryContact)}
                                                                                    helperText={(touched[AUTOCOMPLETES_PRIMARY_CONTACT.path] && errors.autoCompletes?.primaryContact) || primaryContactApproverHelperText}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid xs={12}>
                                                                    <Switches
                                                                        name={MUTATORS_APPROVER_IS_PRIMARY_CONTACT.path}
                                                                        onChange={(e) => {
                                                                            const checked = e.target.checked;
                                                                            form.change(MUTATORS_APPROVER_IS_PRIMARY_CONTACT.path, checked)
                                                                            form.change(AUTOCOMPLETES_APPROVER.path, checked ? undefined : values?.autoCompletes?.attorney)
                                                                        }}
                                                                        data={{ label: MUTATORS_APPROVER_IS_PRIMARY_CONTACT.label, value: true, disabled: !isPrimaryContactApprover(values?.autoCompletes?.primaryContact?.userId) }}
                                                                        color="secondary"
                                                                    />
                                                                </Grid>

                                                                {!values?.mutators?.attorneyIsPrimaryContact &&
                                                                    <Grid xs={12}>
                                                                        <Autocomplete
                                                                        name={AUTOCOMPLETES_APPROVER.path}
                                                                        disablePortal
                                                                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                        fullWidth
                                                                        blurOnSelect={true}
                                                                        filterSelectedOptions={true}
                                                                        filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                                                        options={attorney_auto_complete_data}
                                                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                                                        renderInput={(params) => (
                                                                            <>
                                                                                <MuiTextField
                                                                                    {...params}
                                                                                    label={AUTOCOMPLETES_APPROVER.label}
                                                                                    required={AUTOCOMPLETES_APPROVER.required}
                                                                                    onClick={(e) => form.blur(AUTOCOMPLETES_APPROVER.path)}
                                                                                    error={Boolean(touched[AUTOCOMPLETES_APPROVER.path] && errors.autoCompletes?.attorney)}
                                                                                    helperText={touched[AUTOCOMPLETES_APPROVER.path] && errors.autoCompletes?.attorney}
                                                                                />
                                                                            </>
                                                                        )}
                                                                    />
                                                                    </Grid>
                                                                }

                                                                <Grid xs={12}>
                                                                    <Autocomplete
                                                                        name={AUTOCOMPLETES_COLLABORATORS.path}
                                                                        //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                                                        fullWidth
                                                                        multiple
                                                                        disableCloseOnSelect
                                                                        limitTags={4}
                                                                        filterOptions={(options, params) => getMultiSelectAutoCompleteFilteredOptions(options, params, values?.autoCompletes?.collaborators)}
                                                                        options={collaborators_auto_complete_data}
                                                                        renderInput={(params) => (
                                                                            <>
                                                                                <MuiTextField
                                                                                    //MUI-RFF textfield does filtering does not work well with multi select autocomplete, so we use MUI textfield here
                                                                                    {...params}
                                                                                    label={AUTOCOMPLETES_COLLABORATORS.label}
                                                                                />
                                                                            </>
                                                                        )}
                                                                        renderTags={(tagValue, getTagProps) =>
                                                                            tagValue.map((option, index) => {
                                                                              const { key, ...tagProps } = getTagProps({ index });
                                                                              
                                                                              return (
                                                                                <Chip
                                                                                  key={key}
                                                                                  label={option.label}
                                                                                  {...tagProps}
                                                                                  disabled={option.userId === userData.userId}
                                                                                />
                                                                              );
                                                                            })
                                                                          }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>

                                                        {/* response address */}
                                                        <Stack>
                                                            <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>
                                                            <Grid container spacing={2}>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1.required}
                                                                        label={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1.label}
                                                                        name={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_1.path}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2.required}
                                                                        label={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2.label}
                                                                        name={DEMAND_DETAILS_DEMAND_RESPONSE_ADDRESS_2.path}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={12} md={4}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={DEMAND_DETAILS_DEMAND_RESPONSE_CITY.required}
                                                                        label={DEMAND_DETAILS_DEMAND_RESPONSE_CITY.label}
                                                                        name={DEMAND_DETAILS_DEMAND_RESPONSE_CITY.path}
                                                                    />
                                                                </Grid>
                                                                <Grid xs={6} md={4}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={DEMAND_DETAILS_DEMAND_RESPONSE_STATE.required}
                                                                        label={DEMAND_DETAILS_DEMAND_RESPONSE_STATE.label}
                                                                        name={DEMAND_DETAILS_DEMAND_RESPONSE_STATE.path}
                                                                        select
                                                                    >
                                                                        {stateLabelValues.map((option) => (
                                                                            <MenuItem key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid xs={6} md={4}>
                                                                    <TextField
                                                                        fullWidth
                                                                        required={DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE.required}
                                                                        label={DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE.label}
                                                                        name={DEMAND_DETAILS_DEMAND_RESPONSE_ZIP_CODE.path}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>

                                                        {useDemandNotesEnabled &&
                                                            <Stack>
                                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Notes</Typography>
                                                                <Grid container spacing={1}>
                                                                    <Grid xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            required={CUSTOMER_SPECIAL_NOTES.required}
                                                                            label={CUSTOMER_SPECIAL_NOTES.label}
                                                                            name={CUSTOMER_SPECIAL_NOTES.path}
                                                                            inputProps={{ maxLength: 50 }}
                                                                        />
                                                                        <FormHelperText>{values?.customerSpecialNotes ? values.customerSpecialNotes.length : 0}/50</FormHelperText>
                                                                    </Grid>
                                                                </Grid>
                                                            </Stack>
                                                        }
                                                    </Stack>
                                                }
                                            </>
                                        </fieldset> 

                                    </Grid>
                                    {/* Right Column */}
                                    <Grid xs={12} sm={12} md={12} lg={6} xl={6} sx={{ pb: 0 }}>
                                        <Stack spacing={2} sx={{ width: "100%", top: 72, position: "sticky" }}>
                                            <fieldset disabled={formSubmitting}>
                                                <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Documents</Typography>
                                                <DocumentUploader />
                                            </fieldset>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <ConfirmationDialog
                                handleOk={() => {
                                    setDialogState({ ...dialogState, discardChanges: false });
                                    handleClose();
                                }}
                                {...confirmationDialogProps}
                            />
                        </form>
                    )
                }
                }>
            </Form>
            <Snackbar
                open={toastState.show}
                autoHideDuration={7000}
                onClose={e => setToastState({ ...toastState, show: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Paper elevation={8}>
                    <Alert onClose={e => setToastState({ ...toastState, show: false })} severity={toastState.severity} sx={{ width: '100%' }}>{toastState.message}</Alert>
                </Paper>
            </Snackbar>

        </ >
    )
}

export default CreateNewDemand;
