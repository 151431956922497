

import React from 'react';
import { TextField, Switches } from 'mui-rff';
import { successFieldInputProps, successFieldStyle } from '../../Styles';
import { getCurrentDate, autoFillCreateNewDemandForm } from '../Helpers'
import { get } from 'lodash';
import {
    CLAIM_INFO_CLAIMANT_FIRSTNAME,
    CLAIM_INFO_CLIENT_IS_INJURED_PARTY,
    CLAIM_INFO_CLAIM_NUMBER, CLAIM_INFO_INJURED_PARTY,
    SENDING_FIRM_CASE_NUMBER,
    CLAIM_INFO_INSURED_FIRST_NAME,
    CLAIM_INFO_CLIENT_IS_TORTFEASOR,
    CLAIM_INFO_TORTFEASOR,
    CLAIM_INFO_DATE_OF_LOSS
} from '../Schema';

// 'claimInfo.claimNumber'
export const ClaimInfoClaimNumberField = () => {
    return (
        <TextField
            name={CLAIM_INFO_CLAIM_NUMBER.path}
            label={CLAIM_INFO_CLAIM_NUMBER.label}
            required={CLAIM_INFO_CLAIM_NUMBER.required}
            fieldProps={{ validate: CLAIM_INFO_CLAIM_NUMBER.validation }}
            fullWidth
        />
    )
}

// 'claimInfo.claimant.firstName'
export const ClaimInfoClaimantFirstNameField = ({ form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_data_for_auto_completes, clientNameMatchesIntegration }) => {
    return (
        <TextField
            fullWidth
            required={CLAIM_INFO_CLAIMANT_FIRSTNAME.required}
            label={CLAIM_INFO_CLAIMANT_FIRSTNAME.label}
            name={CLAIM_INFO_CLAIMANT_FIRSTNAME.path}
            sx={clientNameMatchesIntegration ? successFieldStyle : {}}
            onChange={(e) => {
                if (get(values, CLAIM_INFO_CLIENT_IS_INJURED_PARTY.path)) {
                    form.change(CLAIM_INFO_INJURED_PARTY.path, e.target.value);
                }
                form.change(CLAIM_INFO_CLAIMANT_FIRSTNAME.path, e.target.value)

                if (get(values, SENDING_FIRM_CASE_NUMBER.path) === "42" && get(values, CLAIM_INFO_CLAIM_NUMBER.path) === "42" && e.target.value === "Precedent") {
                    autoFillCreateNewDemandForm(form, values, attorney_auto_complete_data, carrier_auto_complete_data, user_data_for_auto_completes)
                }
            }}
            InputProps={clientNameMatchesIntegration && successFieldInputProps}
            fieldProps={{ validate: CLAIM_INFO_CLAIMANT_FIRSTNAME.validation }}
        />
    )
}

// 'claimInfo.clientIsInjuredParty'
export const ClaimInfoClientIsInjuredPartyField = ({ form, values }) => {
    return (
        <Switches
            name={CLAIM_INFO_CLIENT_IS_INJURED_PARTY.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(CLAIM_INFO_CLIENT_IS_INJURED_PARTY.path, checked)
                if (checked) {
                    form.change(CLAIM_INFO_INJURED_PARTY.path, get(values, CLAIM_INFO_CLAIMANT_FIRSTNAME.path))
                } else {
                    form.change(CLAIM_INFO_INJURED_PARTY.path, '')
                }
            }}
            data={{ label: CLAIM_INFO_CLIENT_IS_INJURED_PARTY.label, value: true }}
            color="secondary"
        />
    )
}

// 'claimInfo.injuredPartyFullName'
export const ClaimInfoInjuredPartyFullNameField = () => {
    return (
        <TextField
            fullWidth
            required={CLAIM_INFO_INJURED_PARTY.required}
            label={CLAIM_INFO_INJURED_PARTY.label}
            name={CLAIM_INFO_INJURED_PARTY.path}
            fieldProps={{ validate: CLAIM_INFO_INJURED_PARTY.validation }}
        />
    )
}

// 'claimInfo.insuredFirstName'
export const ClaimInfoInsuredFirstNameField = ({ form, values }) => {
    return (

        <TextField
            fullWidth
            required={CLAIM_INFO_INSURED_FIRST_NAME.required}
            label={CLAIM_INFO_INSURED_FIRST_NAME.label}
            name={CLAIM_INFO_INSURED_FIRST_NAME.path}
            onChange={(e) => {
                form.change(CLAIM_INFO_INSURED_FIRST_NAME.path, e.target.value)

                if (values.claimInfo?.insuredIsTortfeasor) {
                    form.change(CLAIM_INFO_TORTFEASOR.path, e.target.value)
                }
            }}
        />
    )
}

// 'claimInfo.insuredIsTortfeasor'
export const ClaimInfoClientIsTortfeasorField = ({ form, values }) => {
    return (
        <Switches
            name={CLAIM_INFO_CLIENT_IS_TORTFEASOR.path}
            onChange={(e) => {
                const checked = e.target.checked;
                form.change(CLAIM_INFO_CLIENT_IS_TORTFEASOR.path, checked)
                if (checked) {
                    form.change(CLAIM_INFO_TORTFEASOR.path, get(values, CLAIM_INFO_INSURED_FIRST_NAME.path))
                } else {
                    form.change(CLAIM_INFO_TORTFEASOR.path, '')
                }
            }}
            data={{ label: CLAIM_INFO_CLIENT_IS_TORTFEASOR.label, value: true }}
            color="secondary"
        />
    )
}

export const ClaimInfoTortfeasorField = () => {
    return (
        <TextField
            fullWidth
            required={CLAIM_INFO_TORTFEASOR.required}
            label={CLAIM_INFO_TORTFEASOR.label}
            name={CLAIM_INFO_TORTFEASOR.path}
            fieldProps={{ validate: CLAIM_INFO_TORTFEASOR.validation }}
        />
    )
}

// 'claimInfo.dateOfLoss'
export const ClaimInfoDateOfLossField = ({ form, values }) => {
    return (
        <TextField
            name={CLAIM_INFO_DATE_OF_LOSS.path}
            inputProps={{ max: getCurrentDate() }}
            label={CLAIM_INFO_DATE_OF_LOSS.label}
            type={CLAIM_INFO_DATE_OF_LOSS.fieldType}
            InputLabelProps={{ shrink: true }}
            required={CLAIM_INFO_DATE_OF_LOSS.required}
            fullWidth={true}
            fieldProps={{ validate: CLAIM_INFO_DATE_OF_LOSS.validation }}
        />
    )
}
