import React from 'react';
import {
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Image as ImageIcon,
  LocalHospital as LocalHospitalIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';

export const parseQueryStringToObject = (queryString) => {
  const queryParams = new URLSearchParams(queryString);
  const authSuccess = queryParams.get('authSuccess') && queryParams.get('authSuccess') === 'true';
  const integrationState =
    queryParams.get('integrationState') && queryParams.get('integrationState').replaceAll("'", '"');
  const integrationStateJson = JSON.parse(integrationState);

  return {
    ...(authSuccess && { authSuccess }),
    ...(integrationState && { integrationState: integrationStateJson }),
  };
};

export const getFileIcon = (fileName) => {
  if (fileName.endsWith('.pdf')) return <InsertDriveFileIcon color="action" />;
  if (fileName.endsWith('.jpeg')) return <ImageIcon color="action" />;
  if (fileName.endsWith('.docx')) return <DescriptionIcon color="action" />;
  return <InsertDriveFileIcon color="action" />;
};

export const getFolderIcon = (folder) => {
  switch (folder) {
    case 'Medical attachments':
      return <LocalHospitalIcon color="secondary" />;
    default:
      return <FolderIcon color="secondary" />;
  }
};
